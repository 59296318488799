<AppSnackBar :message="showMsg" :notificationType="'success'" v-if="isProfileUpdated"></AppSnackBar>
<AppSnackBar :message="fileErrorSnackbar?.Message" :notificationType="fileErrorSnackbar?.type" v-if="isfileFormatError">
</AppSnackBar>
<AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>
<app-loader v-if="profileUpdateLoading || imgUploading || isLoading "></app-loader>
<div class="dashboard-right-inner">
    <Breadcrumb :id="$route.params.id" />
    <!-- <div class="breadcrumb">
        <a href="#">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                        d="M11.0025 2.38854C10.4323 1.87627 9.56767 1.87626 8.9975 2.38854L3.4975 7.33008C3.18086 7.61457 3 8.0202 3 8.44587V15.5C3 16.3284 3.67157 17 4.5 17H6.5C7.32843 17 8 16.3284 8 15.5V11.5C8 11.2239 8.22386 11 8.5 11H11.5C11.7761 11 12 11.2239 12 11.5V15.5C12 16.3284 12.6716 17 13.5 17H15.5C16.3284 17 17 16.3284 17 15.5V8.44587C17 8.0202 16.8191 7.61457 16.5025 7.33008L11.0025 2.38854Z"
                        fill="#6C707D" />
            </svg>
        </a>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.93934 4.06066L8.87872 8.00003L4.93934 11.9394L6 13L11 8.00003L6 3L4.93934 4.06066Z" fill="#ADB2BD" />
        </svg>
        <a class="color-sky-gray" href="#">FC Brisbane Warriors</a>
    </div> -->


    <div class="max-height-100--60 overflow-y-auto">
        <div class="dashboard-header">
            <div>
                <h1 class="font-28 font-34-sm-big font-w-300">My Profile</h1>
                <span class="font-14 color-light2">Manage your profile information</span>
            </div>
        </div>

        <!--dashboard-content-->
        <div class="dashboard-content">
            <div>
                <div class="d-flex flex-wrap justify-content-start mt-3">
                    <div class="me-4 mb-2 pe-1">
                        <avatar class="av-size-104" :type="'image'" :size="'null'" :border="false" :fill-mode="'outline'"
                            :theme-color="secondary" :rounded="'full'">
                            <img v-if="tempPhotoPath || user.userPhotoPath"
                                :src="tempPhotoPath ? tempPhotoPath : user.userPhotoPath" />
                            <img v-else src="../../../assets/images/profile.png" />
                        </avatar>
                        <!--<kbutton v-if="2 > 1" :icon="'close'" :size="'small'" @click="removeProfilePic"></kbutton>-->
                    </div>
                    <div>
                        <h4 class="font-19 mb-2">{{user.firstName+" "+ user.lastName}}</h4>
                        <p class="font-16 color-light2 mb-3">{{user.userProfileEmail}}</p>
                        <div class="custom-file-upload-btn">
                            <kbutton :theme-color="'primary'" :class="'font-16'" :fill-mode="'outline'">Change Photo
                            </kbutton>
                            <input type="file" @change="uploadProfilePhoto" accept="image/png, image/jpeg" />
                        </div>
                    </div>
                </div>

                <div class="border-top my-5"></div>


                <div class="row width-943 w-100-sm m-0">
                    <div class="col-md-5 ps-0 pe-0 ps-sm-2 pe-sm-2">
                        <h3 class="font-14 font-w-700 text-color">Profile Details</h3>
                        <p class="m-0 font-12 text-color">Provide your basic information</p>
                    </div>
                    <div class="col-md-7 ps-0 pe-0 ps-sm-2 pe-sm-2">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="k-form-field">
                                    <label>First Name *</label>
                                    <k-input type="text" placeholder="John" v-model="user.firstName"
                                        @keyup="errorMessages.FirstName[0]=''">
                                    </k-input>
                                    <div class="error-msg">{{this.errorMessages?.FirstName?.[0]}}</div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="k-form-field">
                                    <label>Last Name *</label>
                                    <k-input type="text" placeholder="Doe" v-model="user.lastName"
                                        @keyup="errorMessages.LastName[0]=''">
                                    </k-input>
                                    <div class="error-msg">{{this.errorMessages?.LastName?.[0]}}</div>
                                </div>
                            </div>
                        </div>
                        <kbutton v-if="!imgUploading" @click="onUpdate" :theme-color="'primary'" :class="'flex-none'">Save
                            Changes</kbutton>
                    </div>
                </div>

                <div class="border-top my-5"></div>

                <div class="row width-943 w-100-sm m-0">
                    <div class="col-md-5 ps-0 pe-0 ps-sm-2 pe-sm-2">
                        <h3 class="font-14 font-w-700 text-color">Change Password</h3>
                        <p class="m-0 font-12 text-color">Provide a strong, unique password for your profile</p>
                    </div>
                    <div class="col-md-7 ps-0 pe-0 ps-sm-2 pe-sm-2">
                        <div v-for="(item, index) in passwordModel" :key="index" class="k-form-field password-icon">
                            <label>{{item?.title}}</label>

                            <k-input @input="(e) => item.errorMessage = ''"
                                :type="item.isShowPassword ? 'text' : 'password'" :placeholder="'Enter ' + item?.title"
                                v-model="item.value">
                            </k-input>
                            <div class="error-msg">{{ item?.errorMessage }}</div>


                            <span v-if="!item.isShowPassword" @click="toggleVisibility(index)" class="pass-hide-show-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px"
                                    fill="rgba(0,0,0,0.3)">
                                    <path d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z" fill="none"></path>
                                    <path
                                        d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z">
                                    </path>
                                </svg>
                            </span>
                            <span v-if="item.isShowPassword" @click="toggleVisibility(index)" class="pass-hide-show-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px"
                                    fill="rgba(0, 0, 0, 0.3) ">
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                    <path
                                        d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z">
                                    </path>
                                </svg>
                            </span>
                        </div>
                        <kbutton @click="onChangePassword" :theme-color="'primary'" :class="'flex-none'">Save
                            Changes</kbutton>
                    </div>
                </div>

                <!-- <div class="bottom-fixed-button-wrap d-flex justify-content-between bg-white">
                    <kbutton @click="onCancel" :class="'flex-none'">Cancel</kbutton>
                    <kbutton v-if="!imgUploading" @click="onUpdate" :theme-color="'primary'" :class="'flex-none'">Save
                        Changes</kbutton>
                </div> -->
            </div>
        </div>
    </div>

</div>