<template src="./UserProfile.html"></template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import { Upload } from "@progress/kendo-vue-upload";
import { HTTP } from "@/service";
import { Avatar } from "@progress/kendo-vue-layout";
import AppSnackBar from "../../common/AppSnackBar/AppSnackBar";
import SnackBarModel from "@/models/snackBar/snackBarModel";
import AppLoader from "../../common/AppLoader/AppLoader";
import Breadcrumb from "../../common/AppBreadCrumbs/BreadCrumbs.vue";
import { configuration } from "@/configurationProvider";
import axios from "axios";
import { auth } from "@/services/auth.service";

export default {
  name: "UserProfile",
  components: {
    kbutton: Button,
    "k-input": Input,
    Upload,
    avatar: Avatar,
    AppSnackBar,
    AppLoader,
    Breadcrumb,
  },
  data() {
    var fileErrorSnackbarModel = new SnackBarModel();

    return {
      clubId: this.$route.params.id,
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
      uploadDocument:
        configuration.apiBaseUrl +
        "/api/v1/Document?clubId=" +
        this.$route.params.id,
      profilePictureId: null,
      tempProfilePictureId: null,
      tempPhotoPath: "",
      prevData: {},
      fileErrorSnackbar: fileErrorSnackbarModel,
      isfileFormatError: false,
      imgUploading: false,

      isLoading: false,

      // passwordModel: {
      //   existingPassword: {
      //     title: "Existing Password",
      //     value: "",
      //     isShowPassword: false,
      //   },
      //   newPassword: {
      //     title: "New Password",
      //     value: "",
      //     isShowPassword: false,
      //   },
      //   confirmPassword: {
      //     title: "Confirm Password",
      //     value: "",
      //     isShowPassword: false,
      //   },
      // },
      passwordModel: [
        {
          title: "Existing Password",
          value: "",
          isShowPassword: false,
          errorMessage: "",
        },
        {
          title: "New Password",
          value: "",
          isShowPassword: false,
          errorMessage: "",
        },
        {
          title: "Confirm Password",
          value: "",
          isShowPassword: false,
          errorMessage: "",
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters.userDetails;
    },
    errorMessages() {
      return this.$store.state.user.updateErrors;
    },
    isProfileUpdated() {
      return this.$store.state.user.showMsg;
    },
    showMsg() {
      return this.$store.state.user.status.Message;
    },
    profileUpdateLoading() {
      return this.$store.state.user.loading;
    },
    club() {
      return this.$store.getters.clubDetails;
    },
  },
  mounted() {
    this.getUser();
    this.prevData = this.user;
    this.errorMessages;
  },
  methods: {
    passwordValidationHandler() {
      let value = true;

      this.passwordModel.forEach((element) => {
        element.errorMessage = "";
      });

      if (this.passwordModel[0].value == "") {
        this.passwordModel[0].errorMessage = "Existing Password is required.";
        value = false;
      }

      if (this.passwordModel[1].value == "") {
        this.passwordModel[1].errorMessage = "New Password is required.";
        value = false;
      }

      if (this.passwordModel[2].value == "") {
        this.passwordModel[2].errorMessage = "Confirm Password is required.";
        value = false;
      }

      //check if existing and new password is not same
      if (
        this.passwordModel[0].value &&
        this.passwordModel[1].value &&
        this.passwordModel[0].value == this.passwordModel[1].value
      ) {
        this.passwordModel[1].errorMessage =
          "New Password must be different from existing password.";
        value = false;
      } else {
        if (this.passwordModel[1].value) {
          let message = "Password must contain";
          let hasError = false;
          let messeges = [];

          if (this.passwordModel[1].value.length < 8) {
            value = false;
            hasError = true;
            messeges.push("at least 8 characters");
          }

          if (!/[A-Z]/.test(this.passwordModel[1].value)) {
            value = false;
            hasError = true;
            messeges.push("at least one uppercase letter");
          }

          if (!/[a-z]/.test(this.passwordModel[1].value)) {
            value = false;
            hasError = true;
            messeges.push("at least one lowercase letter");
          }

          if (!/[0-9]/.test(this.passwordModel[1].value)) {
            value = false;
            hasError = true;
            messeges.push("at least one number");
          }

          if (
            !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>~\/?]/.test(
              this.passwordModel[1].value
            )
          ) {
            value = false;
            hasError = true;
            messeges.push("at least one special character");
          }

          if (hasError) {
            this.passwordModel[1].errorMessage =
              this.generatePasswordErrorMessage(message, messeges);
          }
        }
      }

      if (
        this.passwordModel[1].value &&
        this.passwordModel[2].value &&
        this.passwordModel[1].value != this.passwordModel[2].value
      ) {
        this.passwordModel[2].errorMessage = "Password does not match.";
        value = false;
      }

      return value;
    },
    generatePasswordErrorMessage(initial, messeges) {
      let message = initial;
      if (messeges.length == 1) {
        message += " " + messeges[0] + ".";
      } else if (messeges.length == 2) {
        message += " " + messeges[0] + " and " + messeges[1] + ".";
      } else {
        for (let i = 0; i < messeges.length; i++) {
          if (i == messeges.length - 1) {
            message += " and " + messeges[i] + ".";
          } else if (i == messeges.length - 2) {
            message += " " + messeges[i];
          } else {
            message += " " + messeges[i] + ",";
          }
        }
      }
      return message;
    },
    toggleVisibility(index) {
      this.passwordModel[index].isShowPassword =
        !this.passwordModel[index].isShowPassword;
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
    getUser() {
      this.$store.dispatch("getUser");
    },
    onCancel() {
      this.user.firstName = this.prevData.firstName;
      this.user.lastName = this.prevData.lastName;
      this.tempProfilePictureId = this.prevData.tempProfilePictureId;

      this.user.userPhotoPath = this.prevData.userPhotoPath;

      this.$store.commit("SET_UPDATE_ERRORS", []);
      this.getUser();
      this.$router.push("/club-details/" + this.clubId + "/dashboard");
    },
    uploadProfilePhoto(e) {
      const image = e.target.files[0];
      if (image.type != "image/jpeg" && image.type != "image/png") {
        this.showNotification("File Type Not Supported.", "error");
        return;
      }

      this.imgUploading = true;

      const size = (image.size / 1024 / 1024).toFixed(2);
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.tempPhotoPath = e.target.result;
        this.user.userPhotoPath = e.target.result;
      };

      const bodyFormData = new FormData();
      bodyFormData.append("photoPath", image);

      var pp = HTTP.post(
        configuration.apiBaseUrl +
          "/api/v1/Document?clubId=" +
          this.$route.params.id,
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then((response) => {
          this.imgUploading = false;
          this.tempProfilePictureId = response.data.id;
        })
        .catch((err) => {
          this.imgUploading = false;
        });
    },
    removeProfilePic() {
      this.tempPhotoPath = "";
      this.user.profilePictureId = null;
      this.tempPhotoPath = null;
      this.user.userPhotoPath = "";
    },
    onChangePassword() {
      if (this.passwordValidationHandler()) {
        this.updatePasswordHandler();
      }
    },
    redirectLogout() {
      if (configuration.enableIdentityServer) {
        auth.logout();
      }
      localStorage.removeItem("clubeez_session");
      window.location.href = window.location.origin + "/login";
    },
    async updatePasswordHandler() {
      if (this.passwordValidationHandler()) {
        const payload = {
          email: this.user.userProfileEmail,
          currentPassword: this.passwordModel[0].value,
          newPassword: this.passwordModel[1].value,
        };

        this.isLoading = true;
        let token = "";

        if (configuration.enableIdentityServer) {
          token = await auth.getAccessToken();
        } else {
          token = localStorage.getItem("clubeez_session");
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        HTTP.put(
          configuration.identity.authority + "/api/clients/change-password",
          payload,
          {
            headers: headers,
          }
        )
          .then((response) => {
            this.isLoading = false;
            this.passwordModel[0].value = "";
            this.passwordModel[1].value = "";
            this.passwordModel[2].value = "";

            this.showNotification(
              "Password has been changed successfully.",
              "success"
            );
          })
          .catch((error) => {
            this.isLoading = false;
            this.showNotification(error.response?.data.Status.Message, "error");
          });
      }
    },
    onUpdate() {
      var payload = {
        id: this.user.id,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        profilePictureId: this.tempProfilePictureId
          ? this.tempProfilePictureId
          : this.user.profilePictureId,
      };
      this.$store.dispatch("updateUser", payload);
      this.prevData = this.user;
    },
    isClub() {
      return this.club.isAssociation ? "association" : "club";
    },
  },
};
</script>

<style></style>
